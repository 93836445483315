<template>
    <!-- Businesses -->
    <section class="businesses-Near-you" v-if="businesses.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="title-block text-center">
                        <h1 class="text-start text-md-center">
                            Top Rated Businesses Near You
                            <span class="see-all-button">
                                <router-link :to="'/listing/' + storedLoc?.data?.value">See all <fa icon="long-arrow-alt-right" /></router-link>
                            </span>
                        </h1>
                    </div>
                </div>
            </div>
            <div class="row flex">
                <div class="col-lg-3 col-md-4 col-sm-6 col-9 p-2" v-for="business in businesses" :key="business.id">
                    <router-link :to="business.title_slug" class="text-black tdn business-img" :style="businessBgImage(business.profile_pic)" ></router-link>
                    <div class="p-2 bnu-bottom">
                        <router-link :to="business.title_slug" class="text-black tdn"><h3 class="">{{business.title}}</h3></router-link>
                        <p class="m-0 address">
                            <span>{{business.city}}, {{business.state}}</span>
                            <span class="px-2"  v-if="business.distance > 0"><fa icon="location-arrow" /> {{business.distance}} mi</span>
                        </p>
                        <div class="d-flex rating">
                            <span style="padding-top: 4px;padding-right: 5px;" v-if="business.rating">{{business.rating}}</span>
                            <star-rating :rating="convertToNumbers(business.rating)" :title="business.rating" :round-start-rating="false" :inline="false" :star-size="22" :show-rating="false" :read-only="true"></star-rating>
                            <span style="padding-top: 3px;padding-left: 5px;">({{business.total_reviews}})</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Businesses -->
</template>

<script setup>
import { ref, watch } from 'vue'
import StarRating from 'vue-star-rating'
import { useStorage } from "vue3-storage";
import axios from 'axios'
import { useLocationStore } from '@/stores/location'

const locationStore = useLocationStore()
const storage = useStorage()
const businesses = ref([])

const storedLoc = storage.getStorageSync('location')

const businessBgImage = (image)=>{
    if (image) {
        return 'background-image: url("' + image + '")'
    }else{
        return 'background-image: url("'+ process.env.VUE_APP_ASSET_URL +'business_logo/default.jpg")'
    }
}
const convertToNumbers = (data) => {
    return parseInt(data)
}

function getHomePageBusinesses(currentlocation, location='') {
    axios.get('home_businesses',{params: { ...currentlocation, location: location }}).then(function (response) {
        if (response.data.status === 'success') {
            businesses.value = response.data.data
        }
    });
}

watch([() => locationStore.currentLocation, () => locationStore.location], ([newCurrentLocation, newLocation]) => {
        if(newCurrentLocation && newLocation){
            getHomePageBusinesses(newCurrentLocation, newLocation?.data?.value)
        }
    },
    { deep: true }
)
</script>

<style scoped>
    .business-img{
        height: 200px;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .title-block h1{
        color: #111111;
        font-weight: bold;
    }
    .businesses-Near-you{
        background: white;
    }
</style>