<template>
    <div>
        <div class="search-content bg-white">
            <!-- Desktop Search -->
            <div class="row d-none d-md-flex m-0">
                <div class="col-lg-6 col-md-6 mb-0">
                    <div class="no-gutters br-2 search-autocomplete">
                        <custom-dropdown
                            id="mainSearchAutocomplete"
                            placeholder="Salon, service, business name"
                            :items="servicesAndBusinessList"
                            :itemProjection="AutocompleteItemProjection"
                            :loader="true"
                            :isLoading="isLoading"
                            :minInputLength="0"
                            :groups="true"
                            :defaultItem="selectedService"
                            @onFocus="getPopularSuggessions"
                            @onInput="getSearchSuggessions"
                            @selectItem="updateService"
                        >
                            <template #list-item-text="slot">
                                <img v-if="slot.item.value.type == 'business'" :src="slot.item.value.icon ? slot.item.value.icon:'https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg'" class="img-fluid suggession-business-img" />
                                <span v-html="slot.boldMatchText(slot.itemProjection(slot.item))"></span>
                            </template>
                        </custom-dropdown>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 mb-0">
                    <div class="no-gutters br-2 row">
                        <div class="col-lg-9 col-md-8 mb-0 bg-white">
                            <div class="no-gutters br-2">
                                <Multiselect class="form-control loc-search-bar" v-model="search_.location" @select="updateAddress" @clear="updateAddress('clear')" v-bind="locationConfig" >
                                    <template v-slot:option="{ option }">
                                        <fa v-if="option.value == 'current-location'" icon="location-arrow" class="text-blue me-2" />
                                        <fa v-else icon="map-marker-alt" class="text-secondary me-2" />
                                        <span :class="option.value == 'current-location' ? 'text-blue':''">{{option.label}}</span>
                                    </template>
                                </Multiselect>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 mb-0">
                            <button class="btn btn-search" v-on:click="searchIt" :class="this.$route.name == 'Listing' ? 'search_listing_page':''" >Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Mobile Search  :class="$route.name == 'Listing' ? 'mob1-search':''" -->
            <div class="form row d-flex d-md-none mob-search">
                <div class="col-12 mb-0 bg-white p-0 rounded">
                    <div class="no-gutters bg-white br-2" v-on:click="setIndex">
                        <!-- <router-link v-if="$route.name == 'Listing'" :to="'/'" class="text-dark d-bloc d-sm-none left_icon"><fa icon="angle-left" /></router-link> -->
                        <input readonly type="text" data-bs-toggle="modal" data-bs-target="#searchleModal" class="form-control search-bar bg-white" placeholder="Services, Business names…">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal search-modal fade" id="searchleModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="searchleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="exampleModalLabel">Search</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="resetIndex"></button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-search">
                            <div class="form row">
                                <div class="col-lg-5 col-md-12 mb-0">
                                    <div class="form-group">
                                        <div class="search-autocomplete">
                                            <custom-dropdown
                                                id="mainSearchAutocompleteMobile"

                                                placeholder="Service, salon, business name"
                                                :items="servicesAndBusinessList"
                                                :itemProjection="AutocompleteItemProjection"
                                                :loader="true"
                                                :isLoading="isLoading"
                                                :minInputLength="0"
                                                :groups="true"
                                                @onFocus="getPopularSuggessions"
                                                @onInput="getSearchSuggessions"
                                                @selectItem="updateService"
                                            >
                                                <template #list-item-text="slot">
                                                    <img v-if="slot.item.value.type == 'business'" :src="slot.item.value.icon ? slot.item.value.icon:'https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg'" class="img-fluid suggession-business-img" />
                                                    <span v-html="slot.boldMatchText(slot.itemProjection(slot.item))"></span>
                                                </template>
                                            </custom-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-12 mb-0">
                                    <div class="form-group">
                                        <Multiselect class="form-control loc-search-bar" v-model="search_.location" @select="updateAddress" @clear="updateAddress('clear')" v-bind="locationConfig" >
                                            <template v-slot:option="{ option }">
                                                <fa v-if="option.value == 'current-location'" icon="location-arrow" class="text-blue me-2" />
                                                <fa v-else icon="map-marker-alt" class="text-secondary me-2" />
                                                <span :class="option.value == 'current-location' ? 'text-blue':''">{{option.label}}</span>
                                            </template>
                                        </Multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" v-on:click="searchIt" class="btn btn-block search-button py-2 fw-bold" :class="this.$route.name == 'Listing' ? 'search_listing_page':''">Search</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="sticky-search">
            <div class="row mob-search border rounded">
                <div class="bg-white p-0 rounded">
                    <input disabled type="text" data-bs-toggle="modal" data-bs-target="#searchleModal" class="form-control bg-white border-0" placeholder="Services, Business names…" style="margin: 1px 0px;font-size: 12px">
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    import CustomDropdown from '@/components/common/customDropdown'
    import Multiselect from '@vueform/multiselect'
    import { useSearchStore } from '@/stores/search'
    import axios from "axios"
    import $ from 'jquery'
    import moment from 'moment'

    export default {
        // props:{
        //     sendLocation:Function
        // },
        components: {
            Multiselect,
            CustomDropdown
        },
        setup() {
            const store = useSearchStore()
            return {
                store
            }
        },
        data() {
            return {
                search_:{
                    service_input:'',
                    service:{},
                    location:null,
                    address:'',
                    f_status:true
                },

                // For Services Field
                most_popular:[{'label':"Popular Searches", 'options':[]}],
                servicesAndBusinessList:[],
                awaitingSearch: null,
                isLoading:false,
                selectedService: null,

                // For Location Field
                default_location:{"label":"Charlotte, NC","value":"Charlotte-NC"},
                locationConfig: {
                    placeholder: 'City, State or Zipcode',
                    filterResults: false,
                    minChars: 1,
                    groups:false,
                    resolveOnLoad: true,
                    clearOnSearch:true,
                    clearOnSelect:true,
                    delay: 200,
                    loading:false,
                    canDeselect:false,
                    searchable: true,
                    options: async (query) => {
                        return await this.getLocations(query)
                    }
                },
                updatedAddress:''
            }
        },
        methods: {
            // For Services Input
            AutocompleteItemProjection(item){
                return item.label
            },
            getPopularSuggessions(value){
                if (value.input === '') {
                    this.servicesAndBusinessList = this.most_popular
                }
            },
            getSearchSuggessions(value){
                this.search_.service_input = value.input
                clearTimeout(this.awaitingSearch)
                if (value.input === '') {
                    this.servicesAndBusinessList = this.most_popular
                    this.isLoading = false;
                }else if (value.input != '') {
                    this.isLoading = true;
                    let thiss = this
                    this.awaitingSearch = setTimeout(function () {
                        thiss.getServicesAndBusinesses(value.input);
                    }, 1000);
                }
            },
            getPopularServices(){
                let thiss = this
                axios.get('popular_services').then(function (response) {
                    if (response.data.status == 'success') {
                        response.data.data.forEach(element => {
                            thiss.most_popular[0].options.push({"label":element.title,"value":{'id':element.id,'name':element.title,'icon':'','type':'service'}})
                        })
                    }
                })
            },
            async getServicesAndBusinesses(query,isDefault=false){
                let data = await axios.get("search_suggestions/"+query).then(response => response.data);
                this.servicesAndBusinessList = data;
                if (isDefault) {
                    $("#mainSearchAutocomplete").val(query)
                }
                this.isLoading = false;

                // if (value == null && this.$route.name == 'Listing' && this.$route.params.service != undefined && this.$route.params.service != '' && this.$route.params.service != null) {
                //     value = this.$route.params.service.replace(new RegExp("\\+","g"),' ');
                //     return axios.get("search_suggestions/"+value).then(response => response.data)
                // }else if (value == null) {
                //     return this.most_popular
                // }else if (value != null) {
                //     return axios.get("search_suggestions/"+value).then(response => response.data)
                // }
            },
            serviceDetail(name){
                let thiss= this
                axios.get('service_detail_for_search_suggestion/'+name).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.search_.service = response.data.data
                        // console.log(thiss.search_.service)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                });
            },
            updateService(value){
                if(value.type == 'business'){
                    this.$router.push({ name:'BusinessProfile', params:{slug:value.slug}})
                }else{
                    this.search_.service_input = value.name
                    this.search_.service = value
                }
            },

            // For Location Input
            getLocations(query){
                if (query != null) {
                    return axios.get("get_locations/"+query).then(response => response.data)
                }else{
                    const options = [{"label":"Current Location","value":"current-location"}]
                    const storedLoc = this.$storage.getStorageSync('location')
                    if (this.search_.f_status && this.$route.name == 'Listing') {
                        this.search_.f_status = false
                        options.push({"label":this.$route.params.location.replace(/-/g, ' '),"value":this.$route.params.location})
                    }else if(this.$route.name === 'Home'
                        && this.$router.options.history.state.forward !== null
                        && this.$router.options.history.state.forward.includes('/listing/')
                        && storedLoc !== undefined && storedLoc !== null && storedLoc !== ''
                        ){
                            options.push({"label":storedLoc.data.label,"value":storedLoc.data.value})
                    }
                    return options
                }
            },
            updateAddress(value,option){
                if (value == 'current-location') {
                    this.getCurrentLocation('update-input')
                }else if (value == 'clear') {
                    this.updatedAddress = ''
                }else{
                    this.search_.address = value
                    this.updatedAddress = {data:option,type:'custom'}
                    // this.updateStorageLocation(option,'custom')
                }
            },
            getCurrentLocation(action=''){
                if (action === 'update-storage') {
                    this.updateStorageLocation(this.default_location,'default')
                }

                // Check Browser support
                if (navigator.geolocation) {
                    // Supported Browser
                    // Get current location
                    let thiss = this
                    navigator.geolocation.getCurrentPosition(function (position) {
                        // Allowed
                        const lat = position.coords.latitude;
                        const lng = position.coords.longitude;
                        const geocoder = new window.google.maps.Geocoder();

                        var latlng = new window.google.maps.LatLng(lat, lng);
                        geocoder.geocode({'latLng': latlng}, function(results, status) {
                            // console.log(results)
                            if (status == window.google.maps.GeocoderStatus.OK) {
                                if (results[1]) {
                                    //find City, State
                                    var loc_obj = {
                                        city:'',
                                        state:''
                                    }
                                    results[0].address_components.forEach(element => {
                                        if(element.types.indexOf("locality") !== -1){
                                            loc_obj.city = element.long_name
                                        }else if(element.types.indexOf("administrative_area_level_1") !== -1){
                                            loc_obj.state = element.short_name
                                        }
                                    });
                                    if (action === 'update-storage') {
                                        thiss.updateStorageLocation({"label":loc_obj.city+', '+loc_obj.state,"value":loc_obj.city+'-'+loc_obj.state},'current-location')
                                    }else if (action === 'update-input') {
                                        thiss.search_.address = loc_obj.city+'-'+loc_obj.state
                                        $(".loc-search-bar .multiselect-single-label").html(loc_obj.city+', '+loc_obj.state)
                                        thiss.updatedAddress = {data:{label:loc_obj.city+', '+loc_obj.state,value:loc_obj.city+'-'+loc_obj.state},type:'current-location'}
                                    }
                                    // if (thiss.$route.name != 'Listing') {
                                    //     thiss.sendLocation(loc_obj.city+'-'+loc_obj.state)
                                    // }
                                } else {
                                    //thiss.updateStorageLocation(thiss.default_location,'default')
                                }
                            }
                        });

                        //this.$storage.setStorageSync('location',{data:position, date: moment().format('YYYY-MM-DD'),last_selected:null})
                    },function () {
                        // Not Allowed
                        console.log("Check Location: ","Not Allowed.")
                        if (action === 'update-input') {
                            const storedLoc = thiss.$storage.getStorageSync('location')
                            if (storedLoc !== undefined && storedLoc.type === 'current-location') {
                                $(".loc-search-bar .multiselect-single-label").html(storedLoc.data.label)
                                thiss.search_.address = storedLoc.data.value;
                            }else{
                                $(".loc-search-bar .multiselect-single-label").html(thiss.default_location.label)
                                thiss.search_.address = thiss.default_location.value;
                            }
                        }
                        //this.updateStorageLocation(this.default_location,'default')
                    });
                }else{
                    console.log("Check Browser: ","Geolocation is not supported by this browser.")
                    //this.updateStorageLocation(this.default_location,'default')
                }
            },
            updateStorageLocation (data,type){
                this.$storage.setStorageSync('location',{data:data, date:moment().format('YYYY-MM-DD'),type:type})
            },

            // For Modal Design
            setIndex(){
                $('.search-bar-content').css("z-index", "1053")
            },
            resetIndex(){
                $('.search-bar-content').css("z-index", "1052")
            },

            // Get Search Results
            searchIt(){
                // updateStorageLocation
                if (this.search_.address == '' || this.search_.address == undefined || this.search_.address == null || this.search_.address == 'undefined') {
                    if (this.$route.name != 'Listing') {
                        const storedLoc = this.$storage.getStorageSync('location')
                        this.search_.address = storedLoc.data.value
                    }else{
                        this.search_.address = this.$route.params.location
                    }
                }
                $("#searchleModal").modal('hide')
                if (this.updatedAddress !== '') {
                    this.updateStorageLocation(this.updatedAddress.data,this.updatedAddress.type)
                }
                const params = {location:this.search_.address}

                if (this.search_.service_input == '') {
                    params.service = ''
                }else if (this.search_.service != null && this.search_.service != '' && this.search_.service != undefined && this.search_.service != 'undefined') {
                    params.service = this.search_.service.name.replace(/ /g, '+');
                }
                // Get records on listing page when filters change like custom watcher
                if (this.$route.name === 'Listing' && (this.$route.params.location !== params.location || this.$route.params.service !== params.service )) {
                    this.store.page = 1
                    this.store.getBusinesses(params)
                }
                this.$router.push({ name:'Listing', params:params,query:this.$route.query})
            },
        },
        mounted() {
            //this.$storage.removeStorageSync('location')
            const storedLoc = this.$storage.getStorageSync('location')
            // console.log(storedLoc)
            if (storedLoc === undefined || storedLoc === null || storedLoc === ''){
                // Get Current Location
                this.getCurrentLocation('update-storage')
            }else{
                // console.log(this.$route.name,this.$router.options.history.state.forward)
                if(this.$route.name === 'Home' && this.$router.options.history.state.forward !== null && this.$router.options.history.state.forward.includes('/listing/')){
                    this.search_.location = storedLoc.data.value
                    this.search_.address = storedLoc.data.value
                }
            }
        },
        created() {
            if (this.$route.name == 'Listing') {
                if (this.$route.params.service != undefined && this.$route.params.service != '' && this.$route.params.service != null) {
                    const service = this.$route.params.service.replace(/\+/g, " ")
                    this.selectedService = service
                    this.serviceDetail(this.$route.params.service)
                    this.getServicesAndBusinesses(service,true);
                }
                this.search_.location = this.$route.params.location
            }
            this.getPopularServices()
        },
    }
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
    .multiselect.is-active {
        border-left: 0 !important;
    }
</style>
<style>
    /* .search_listing_page{
        color: #000 !important;
        background: #cbcbcf !important;
    } */
    /* .search_listing_page:hover{
        background: darkgray !important;
    } */
    .multiselect-single-label{
        font-size: 18px;

    }
    .multiselect-clear{
        z-index: 8;
    }

    .suggession-business-img{
        width: 40px;
		height: 40px;
		border-radius: 100%;
		margin-right: 12px;
    }
    .text-blue{
        color: rgb(58 89 165)
    }
    .multiselect{
        min-height: unset
    }
    .multiselect-dropdown{
        bottom: -10px;
        left: calc(var(--ms-border-width, 1px)*-1 + -11px);
        border: none;
        border-top: 1px solid #eeeeee;
        right: 1px;
        box-shadow: 0 7px 12px rgb(159 159 159)
    }
    .search-modal .multiselect-dropdown{
        bottom: -1px;
        left: calc(var(--ms-border-width, 1px)*-1 + 1px);
    }
    .multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label{
        padding-left: 35px;
    }
    .modal-search .multiselect-search{
        border: 1px solid #ddd;
    }
    .modal-search .loc-search-bar{
        padding: 0;
    }

</style>