import { defineStore } from 'pinia'
import {toRaw} from 'vue'
import $ from 'jquery'
import { getCurrentLocation } from '@/components/location/Location'

export const useSearchStore = defineStore('search', {
  state: () => ({ 
    filters_:{
      render: 'business',
      business_type: null,
      sort_by: 'featured',
      currency_range: null,
      isOpen: false,
      hasDeals: false,
      rating: 0,
      gender: "",
      distance: 0,
      limit: 10,
    },
    businessList:[],
    map:'',
    gmarkers:[],
    activeMarker:{
      business: {},
      currentMarker: ''
    },
    isLoading:false,
    load_more: {value:false,isLoading:false},
    page: 1
   }),
  actions: {
    // TODO: Add business type in params after finishing services and types issue
    async getBusinesses(params,isAppend=false) {
      const location = await getCurrentLocation();
      isAppend ? this.isLoading = false : this.isLoading = true;
      let filters = {...this.filters_, ...params, ...location};
      filters.page = this.page
      const resp = await this.axios.get('business_listing',{params: filters})
      if (resp.data.status === 'success') {
        const data = resp.data.data.data
        if (isAppend) {
          this.businessList = this.businessList.concat(data)
        }else{
          this.businessList = data
        }
        this.updateMapMarkers()
        if (resp.data.data.next_page_url === null) {
          this.load_more.value = false
        }else{
          this.load_more.value = true
        }
      }
      this.isLoading = false
      this.load_more.isLoading = false
    },
    updateMapMarkers(){
      this.removeAllMarkers()
      if (this.businessList.length > 0) {
        const bounds = new window.google.maps.LatLngBounds()
        this.businessList.forEach((business,i) => {
            // Draw Markers
            const marker = new window.google.maps.Marker({
                position: {lat: parseFloat(business.lat), lng: parseFloat(business.lng)},
                map: this.map,
                title: business.title,
                icon: 'https://s3.us-east-2.amazonaws.com/images.ondaq.com/marker/pin.png',
                index: i
            });
            this.gmarkers.push(marker)
            bounds.extend(marker.getPosition())

            // Add Event Listeners
            // Display info window on mouse over
            let thiss = this
            marker.addListener('mouseover', function () {
                marker.setIcon('https://s3.us-east-2.amazonaws.com/images.ondaq.com/marker/marker.png')
                thiss.activeMarker.currentMarker = this
                thiss.activeMarker.business = business
                thiss.displayInfoWindow();
            });

            // Hide info window on mouse out
            marker.addListener('mouseout', function () {
                marker.setIcon('https://s3.us-east-2.amazonaws.com/images.ondaq.com/marker/pin.png')
                thiss.hideInfoWindow()
            });

            if (window.screen.width < 991) {
                const mobContentString = this.makeInfoHTML(business)

                const infowindow = new window.google.maps.InfoWindow({
                    content: mobContentString,
                });

                marker.addListener("mouseover", () => {
                    marker.setIcon('https://s3.us-east-2.amazonaws.com/images.ondaq.com/marker/marker.png')
                });
                marker.addListener("mouseout", () => {
                    marker.setIcon('https://s3.us-east-2.amazonaws.com/images.ondaq.com/marker/pin.png')
                });

                marker.addListener("click", () => {
                    infowindow.open({
                        anchor: marker,
                        map: this.map,
                        shouldFocus: false,
                    });
                });
            }
        });
        this.map.fitBounds(bounds)
      }
    },
    removeAllMarkers(){
      for (var index = 0; index < this.gmarkers.length; index++) {
        toRaw(this.gmarkers[index]).setMap(null)
      }
      this.gmarkers = []
    },
    displayInfoWindow() {
      var projection = this.map.getProjection();
      var mapScale = Math.pow(2, this.map.getZoom());
      var latLngNW = new window.google.maps.LatLng(this.map.getBounds().getNorthEast().lat(), this.map.getBounds().getSouthWest().lng());
      var pointNW = projection.fromLatLngToPoint(latLngNW);
      var point = projection.fromLatLngToPoint(this.activeMarker.currentMarker.getPosition());
      var markerOffset = new window.google.maps.Point(Math.floor((point.x - pointNW.x) * mapScale), Math.floor((point.y - pointNW.y) * mapScale));


      const contentString = this.makeInfoHTML()
      $('#customWindow').html(contentString);

      var left_window = parseFloat(markerOffset.x)-parseFloat(283);
      var top_window = parseFloat(markerOffset.y)-parseFloat(151);
      var left_window_arrow = parseFloat(markerOffset.x)-parseFloat(15);
      var top_window_arrow = parseFloat(markerOffset.y)-parseFloat(40);
      $('#customWindow').css({left:left_window+'px',top:top_window+'px'});
      $('#customWindowArrow').css({left:left_window_arrow+'px',top:top_window_arrow+'px'});

      // Display the elements
      $('#customWindow').show();
      $('#customWindowArrow').show();
      //map.fitBounds(bounds);
    },
    hideInfoWindow(){
      $('#customWindow').hide();
      $('#customWindowArrow').hide();
    },
    getStarsImage(rating=0){
      let url = 'https://s3.us-east-2.amazonaws.com/images.ondaq.com/star-rating/default.jpg';
      if (rating == 1) {
          url = "https://s3.us-east-2.amazonaws.com/images.ondaq.com/star-rating/starone.png"
      }else if (rating == 2) {
          url = "https://s3.us-east-2.amazonaws.com/images.ondaq.com/star-rating/startwo.png"
      }else if (rating == 3) {
          url = "https://s3.us-east-2.amazonaws.com/images.ondaq.com/star-rating/starthree.png"
      }else if (rating == 4) {
          url = "https://s3.us-east-2.amazonaws.com/images.ondaq.com/star-rating/starfour.png"
      }else if (rating == 5) {
          url = "https://s3.us-east-2.amazonaws.com/images.ondaq.com/star-rating/starfive.png"
      }
      return url
    },
    makeInfoHTML(bInfo = null){
      let c = "border-0"
      if (bInfo === null) {
        c = ""
        bInfo = this.activeMarker.business
      }

      if (!bInfo.profile_pic) {
          bInfo.profile_pic = "https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg"
      }
      const starRating = this.getStarsImage(bInfo.rating)
      const str =
      '<a href="'+window.location.origin+'/'+bInfo.title_slug+'"><div class="result-box my-0 '+c+'" style="width:300px;box-shadow: 0 0 6px rgba(51 51 51 / 10%)">'+
          '<div class="salon-image" style="width:auto">'+
              '<img src="'+bInfo.profile_pic+'" width="100px" />'+
          '</div>'+
          '<div class="salon-detail">'+
              '<h6 class="salon-name text-dark">'+bInfo.title+'</h6>'+
              '<div class="salon-rating">'+
                  '<img src="'+starRating+'" width="100px" />'+
              '</div>'+
              '<div class="salon-address">'+bInfo.city+', '+bInfo.state+'</div>'+
          '</div>'+
      '</div></a>';
      return str
    },

    // Filters Work
    updateFilters(route,filters = null){
      if (filters === null) {
        for (const key in route.query) {
          this.filters_[key] = route.query[key]
        }
      }else{
        let query = {
          sort_by: filters.sort_by,
          render: filters.render,
        }
        filters.rating > 0 ? query.rating=filters.rating : ''
        filters.currency_range !== null  ? query.currency_range=filters.currency_range : ''
        filters.isOpen ? query.isOpen = true : ''
        filters.hasDeals ? query.hasDeals = true : ''
        filters.gender !== '' ? query.gender = filters.gender : ''
        filters.distance > 0 ? query.distance=filters.distance : ''
        this.router.push({ name: "Listing", query: query,params:route.params });
        this.getBusinesses(route.params)
      }
    },
    clearFilters(){
      const emptyFilters = {
        currency_range: null,
        isOpen: false,
        hasDeals: false,
        rating: 0,
        gender: "",
        distance: 0,
      }
      this.filters_ = { ...this.filters_, ...emptyFilters };
    }
  }
})