import { useStorage } from 'vue3-storage'
import axios from 'axios'

const storage = useStorage()

const getLocationFromStorage = () => {
    const currentLocation = storage.getStorageSync('currentLocation')
    if (currentLocation) {
        return currentLocation;
    }else{
        return getLocationFromIP()
    }
}

const getLocationFromIP = async () => {
    // Set Default Location
    let locationData = {lat: '35.2027049', lng: '-81.1701694', type: 'default'}
    storage.setStorageSync('currentLocation',locationData)
    // Get Location from IP
    const response = await axios.get("locIP");
    
    if (response.data.status === 'success') {
        if(response.data.data){
            return {lat: response.data.data?.lat, lng: response.data.data?.lng, type: 'current'}
        }else{
            return locationData
        }
    }else{
        return locationData
    }
}

const getCurrentPositionAsync = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords
                    resolve({lat: latitude, lng: longitude, type: 'current'}); 
                },
                (error) => {
                    reject(error);
                }
            );
        } else {
            reject(new Error("Geolocation is not supported"));
        }
    });
}

export async function getCurrentLocation() {
    try {
        const position = await getCurrentPositionAsync();
        return position;
    } catch (error) {
        console.log(error.message)
        return getLocationFromStorage()
    }
}
