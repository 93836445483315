import { defineStore } from 'pinia'
export const useBusinessTypesStore = defineStore('busTypes', {
    state: () => ({ 
        bTypes:[],
        hTypes:[]
    }),
    actions: {
        async getBusinessTypes(tFor='') {
            const params = {}
            if (tFor != ''){
                params.for = tFor
            }
            const resp = await this.axios.get('business_types',{params: params})
            if (resp.data.status === 'success') {
                if (tFor == 'homePage') {
                    this.hTypes = resp.data.data
                }else{
                    this.bTypes = resp.data.data
                }
            }
        },
        // async updatePlan() {
        //     const resp = await axios.put("update_plan",this.plan_).catch(error => {return error.response})
        //     return resp.data
        // },
        // clearPlan(){
        //     this.plan_={
        //         plan:{},
        //         card:'',
        //         business_id:'',
        //         action:''
        //     }
        // }
    }
})