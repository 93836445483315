<template>
	<div :id="wrapperId" class="simple-typeahead">
		<input
			ref="inputRef"
			:id="inputId"
			class="simple-typeahead-input"
			type="text"
			:placeholder="placeholder"
			v-model="input"
			@input="onInput"
			@focus="onFocus"
			@blur="onBlur"
			autocomplete="shut-up-google"
			v-bind="$attrs"
		/>
			<!-- @keydown.down.prevent="onArrowDown"
			@keydown.up.prevent="onArrowUp"
			@keydown.enter.prevent="selectCurrentSelection"
			@keydown.tab.prevent="selectCurrentSelectionTab" -->
		<div v-click-outside="{ id: id, callBack: nameOfCustomEventToCall }" v-show="isListVisible && groups" class="simple-typeahead-list" >
			<div class="simple-typeahead-list-header" align="center" v-if="isLoading" style="padding: 20px 40px;font-size: 15px;font-weight:normal">
				<span>Searching...</span>
			</div>
			<div v-for="(item, index) in items" :key="index" v-show="!isLoading">
				<!-- <div class="simple-typeahead-list-header" v-if="$slots['list-header']"><slot name="list-header"></slot></div> -->
				<div class="simple-typeahead-list-header">{{item.label}}</div>
				<div
					class="simple-typeahead-list-item grouped"
					v-for="innerItem in item.options" :key="innerItem.id"
					:class="{ 'simple-typeahead-list-item-active': input == itemProjection(innerItem) }"
					@mousedown.prevent
					@click="selectItem(innerItem,this)"
					@mouseenter="currentSelectionIndex = index"
				>
					<span class="simple-typeahead-list-item-text" :data-text="itemProjection(innerItem)" v-if="$slots['list-item-text']">
						<slot name="list-item-text" :item="innerItem" :itemProjection="itemProjection" :boldMatchText="boldMatchText"></slot>
					</span>
					<span class="simple-typeahead-list-item-text" :data-text="itemProjection(innerItem)" v-html="boldMatchText(itemProjection(innerItem))" v-else></span>
				</div>
				<!-- :class="{ 'simple-typeahead-list-item-active': currentSelectionIndex == i }" -->
				<!-- <div class="simple-typeahead-list-footer" v-if="$slots['list-footer']"><slot name="list-footer"></slot></div> -->
			</div>
			<div class="simple-typeahead-list-footer p-3" v-if="input&&!items.length&&!isLoading">
				No results found!
			</div>
		</div>
		<div v-click-outside="{ id: id, callBack: nameOfCustomEventToCall }" v-if="isListVisible && !groups" class="simple-typeahead-list">
			<div class="simple-typeahead-list-header" align="center" v-if="isLoading" style="padding: 20px 40px;font-size: 15px;font-weight:normal">
				<span>Searching...</span>
			</div>
			<div
				class="simple-typeahead-list-item"
				:class="{ 'simple-typeahead-list-item-active': input == itemProjection(item) }"
				v-for="(item, index) in items"
				:key="index"
				@mousedown.prevent
				@click="selectItem(item,this)"
				@mouseenter="currentSelectionIndex = index"
				v-show="!isLoading"
			>
				<span class="simple-typeahead-list-item-text" :data-text="itemProjection(item)" v-if="$slots['list-item-text']"
					><slot name="list-item-text" :item="item" :itemProjection="itemProjection" :boldMatchText="boldMatchText"></slot
				></span>
				<span class="simple-typeahead-list-item-text" :data-text="itemProjection(item)" v-html="boldMatchText(itemProjection(item))" v-else></span>
			</div>
			<div class="simple-typeahead-list-footer p-3" v-if="input&&!items.length&&!isLoading">
				No results found!
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
    import $ from 'jquery'
	const clickOutside = {
		beforeMount: (el,binding) => {
			el.clickOutsideEvent = event => {
				// here I check that click was outside the el and his children
				if (!(el == event.target || el.contains(event.target))) {
					if (event.target.id !== binding.value.id) {
						// and if it did, call method provided in attribute value
						binding.value.callBack();
					}
				}
			};
			document.addEventListener("click", el.clickOutsideEvent);
		},
		unmounted: el => {
			document.removeEventListener("click", el.clickOutsideEvent);
		},
	};

	export default defineComponent({
		name: 'customDropdown',
		emits: ['onInput', 'onFocus', 'onBlur', 'selectItem'],
		inheritAttrs: false,
		directives: {
			"click-outside":clickOutside
		},
		props: {
			id: {
				type: String,
			},
			placeholder: {
				type: String,
				default: '',
			},
			items: {
				type: Array,
				required: true,
			},
			defaultItem: {
				default: null,
			},
			itemProjection: {
				type: Function,
				default(item) {
					return item;
				},
			},
			minInputLength: {
				type: Number,
				default: 2,
				validator: (prop) => {
					return prop >= 0;
				},
			},
			minItemLength: {
				type: Number,
				default: 0,
				validator: (prop) => {
					return prop >= 0;
				},
			},
			groups:{
				type: Boolean,
				default: false,
			},
			isLoading:{
				type: Boolean,
				default: false,
			}
		},
		mounted() {
			if (this.defaultItem !== undefined && this.defaultItem !== null) {
				// this.selectItem(this.defaultItem);
				this.input = this.defaultItem;

			}
		},
		data() {
			return {
				inputId: this.id || `simple_typeahead_${(Math.random() * 1000).toFixed()}`,
				input: '',
				isInputFocused: false,
				currentSelectionIndex: 0,
			};
		},
		methods: {
			nameOfCustomEventToCall(){
				this.isInputFocused = false;
				// console.log("here")
			},
			onInput() {
				if (this.isListVisible && this.currentSelectionIndex >= this.items.length) {
					this.currentSelectionIndex = (this.items.length || 1) - 1;
				}
				this.$emit('onInput', { input: this.input, items: this.items });
			},
			onFocus() {
				//console.log(this.currentSelectionIndex)
				this.isInputFocused = true;
				this.$emit('onFocus', { input: this.input, items: this.items });
			},
			onBlur() {
				//this.isInputFocused = false;
				this.$emit('onBlur', { input: this.input, items: this.items });
			},
			// eslint-disable-next-line no-unused-vars
			// onArrowDown($event) {
			// 	if (this.isListVisible && this.currentSelectionIndex < this.items.length - 1) {
			// 		this.currentSelectionIndex++;
			// 	}
			// 	this.scrollSelectionIntoView();
			// },
			// eslint-disable-next-line no-unused-vars
			// onArrowUp($event) {
			// 	if (this.isListVisible && this.currentSelectionIndex > 0) {
			// 		this.currentSelectionIndex--;
			// 	}
			// 	this.scrollSelectionIntoView();
			// },
			// scrollSelectionIntoView() {
			// 	setTimeout(() => {
			// 		const list_node = document.querySelector(`#${this.wrapperId} .simple-typeahead-list`);
			// 		const active_node = document.querySelector(`#${this.wrapperId} .simple-typeahead-list-item.simple-typeahead-list-item-active`);

			// 		if (!(active_node.offsetTop >= list_node.scrollTop && active_node.offsetTop + active_node.offsetHeight < list_node.scrollTop + list_node.offsetHeight)) {
			// 			let scroll_to = 0;
			// 			if (active_node.offsetTop > list_node.scrollTop) {
			// 				scroll_to = active_node.offsetTop + active_node.offsetHeight - list_node.offsetHeight;
			// 			} else if (active_node.offsetTop < list_node.scrollTop) {
			// 				scroll_to = active_node.offsetTop;
			// 			}

			// 			list_node.scrollTo(0, scroll_to);
			// 		}
			// 	});
			// },
			// selectCurrentSelection() {
			// 	if (this.currentSelection) {
			// 		this.selectItem(this.currentSelection);
			// 	}
			// },
			selectItem(item,thiss) {
				console.log("select item")
				$(".simple-typeahead-list-item").removeClass("simple-typeahead-list-item-active");
				$(thiss).addClass("simple-typeahead-list-item-active");
				if (item.label === 'Current Location') {
					this.input = item.value;
				}else{
					this.input = this.itemProjection(item);
				}
				this.currentSelectionIndex = 0;
				this.$refs.inputRef.blur();
				this.$emit('selectItem', item.value);
				this.isInputFocused = false;
			},
			escapeRegExp(string) {
				return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
			},
			boldMatchText(text) {
				const regexp = new RegExp(`(${this.escapeRegExp(this.input)})`, 'ig');
				return text.replace(regexp, '<strong>$1</strong>');
			},
			clearInput() {
				this.input = '';
			},
			getInput() {
				return this.$refs.inputRef;
			},
			focusInput() {
				this.$refs.inputRef.focus();
				this.onFocus();
			},
			blurInput() {
				this.$refs.inputRef.blur();
				this.onBlur();
			},
		},
		computed: {
			wrapperId() {
				return `${this.inputId}_wrapper`;
			},
			// filteredItems() {
			// 	console.log("filtered")
			// 	console.log(this.items.length)
			// 	const regexp = new RegExp(this.escapeRegExp(this.input), 'i');
			// 	//return this.items.filter((item) => this.itemProjection(item).match(regexp));
			// 	//return this.items.filter((item) => this.itemProjection(item).match(regexp));
			// },
			isListVisible() {
				return this.isInputFocused && this.input.length >= this.minInputLength && this.items.length > this.minItemLength;
			},
			currentSelection() {
				// console.log("current-selection")
				return this.isListVisible && this.currentSelectionIndex < this.items.length ? this.items[this.currentSelectionIndex] : undefined;
			},
		},
	});
</script>

<style scoped>
    .simple-typeahead {
        border-top: none;
        border-left: none;
        border-bottom: none;
        border-right: 1px solid #b7b7b7;
        padding: 0;
        margin: 9px 0;
        height: 32px;
        box-shadow: none!important;
        border-radius: 0;
        font-size: 15px;
        color: #333;
        position: relative;
    }
	.modal-search .simple-typeahead{
		border: 1px solid #ddd;
		padding: 5px;
		height: auto;
		border-radius: 5px;
	}
    .simple-typeahead input
    {
        background-repeat: no-repeat;
        background-position: 10px center;
        width: 100%;
        font-size: 15px;
        padding-left: var(--ms-px,2.3rem);
        padding-right: 20px;
        color: #1b1b1b;
        line-height: 2;
        border: none;
        position: relative;
        margin-bottom: 0;
    }
    .simple-typeahead input:focus
    {
        border: none;
        outline: none;
    }

    .simple-typeahead input::-webkit-input-placeholder{
        color: #9ca3b4;
    }

    .simple-typeahead .simple-typeahead-list{
        top: 42px;
        right: 0px;
        width: calc(100% + 10px) !important;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: 0 7px 12px rgb(159 159 159);
        border-bottom: none !important;
        border-top: 1px solid #eeeeee !important;
        max-height: 217px;
        position: absolute;
        overflow-y: scroll;
        z-index: 9;
        border: none;
        background: #fff;
    }
	.simple-typeahead .simple-typeahead-list::-webkit-scrollbar-thumb,.simple-typeahead .simple-typeahead-list::-webkit-scrollbar-track {
      visibility: hidden;
    }
    .simple-typeahead .simple-typeahead-list:hover::-webkit-scrollbar-thumb,.simple-typeahead .simple-typeahead-list:hover::-webkit-scrollbar-track {
      visibility: visible;
    }

	.modal-search .simple-typeahead .simple-typeahead-list{
		width:100% !important
	}
    .simple-typeahead .simple-typeahead-list .simple-typeahead-list-item,
    .simple-typeahead .simple-typeahead-list .simple-typeahead-list-header{
        background-color: #fff;
        text-align: left;
    }
	.simple-typeahead .simple-typeahead-list .simple-typeahead-list-item{
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        color: #333;
        cursor: pointer;
    }
    .simple-typeahead .simple-typeahead-list .simple-typeahead-list-item.grouped{
		padding: 0.5rem 0.5rem 0.5rem 1.7rem;
	}
    .simple-typeahead .simple-typeahead-list .simple-typeahead-list-item.simple-typeahead-list-item-active,
	.simple-typeahead .simple-typeahead-list .simple-typeahead-list-item:hover{
        background-color: #f3f4f6;
    }
    .simple-typeahead .simple-typeahead-list .simple-typeahead-list-header{
        font-size: 1.1rem;
        font-weight: 500;
        padding: 10px;
        color: #1b1b1b;
    }



	/* .simple-typeahead {
		position: relative;
		width: 100%;
	}
	.simple-typeahead > input {
		margin-bottom: 0;
	}
	.simple-typeahead .simple-typeahead-list {
		position: absolute;
		width: 100%;
		border: none;
		max-height: 400px;
		overflow-y: auto;
		border-bottom: 0.1rem solid #d1d1d1;
		z-index: 9;
	}
	.simple-typeahead .simple-typeahead-list .simple-typeahead-list-header {
		background-color: #fafafa;
		padding: 0.6rem 1rem;
		border-bottom: 0.1rem solid #d1d1d1;
		border-left: 0.1rem solid #d1d1d1;
		border-right: 0.1rem solid #d1d1d1;
	}
	.simple-typeahead .simple-typeahead-list .simple-typeahead-list-footer {
		background-color: #fafafa;
		padding: 0.6rem 1rem;
		border-left: 0.1rem solid #d1d1d1;
		border-right: 0.1rem solid #d1d1d1;
	}
	.simple-typeahead .simple-typeahead-list .simple-typeahead-list-item {
		cursor: pointer;
		background-color: #fafafa;
		padding: 0.6rem 1rem;
		border-bottom: 0.1rem solid #d1d1d1;
		border-left: 0.1rem solid #d1d1d1;
		border-right: 0.1rem solid #d1d1d1;
	}

	.simple-typeahead .simple-typeahead-list .simple-typeahead-list-item:last-child {
		border-bottom: none;
	}

	.simple-typeahead .simple-typeahead-list .simple-typeahead-list-item.simple-typeahead-list-item-active {
		background-color: #e1e1e1;
	} */
</style>
