<template>
    <div>
        <!-- Booking made easy -->
        <section class="BME">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="title-block text-center"><h1>Learn How To Book</h1></div>
                    </div>
                </div>
                <div class="row text-center px-3 px-sm-0">
                    <div class="col-lg-3 col-sm-6">
                        <div class="box">
                            <div class="content-box">
                                <h3 class="display-1 fw-9 m-0 mb-2">01</h3>
                                <p class="m-0">You can start by either searching for a particular service or business or you can create an account first and search later.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="box">
                            <div class="content-box">
                                <h3 class="display-1 fw-9 m-0 mb-2">02</h3>
                                <p class="m-0">Check the profile of the salon or beauty professional that you would like to book an appointment with and choose the service, date and time.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="box">
                            <div class="content-box">
                                <h3 class="display-1 fw-9 m-0 mb-2">03</h3>
                                <p class="m-0">Read their Cancellation and No-show Policy. Add your credit card details before clicking “book now”. Remember that your card will not be charged until after completion of  the service you booked.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="box">
                            <div class="content-box">
                                <h3 class="display-1 fw-9 m-0 mb-2">04</h3>
                                <p class="m-0">Congratulations! You are all set. You will receive a confirmation email and reminders about your appointment.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Booking made easy -->

        <!-- Queue from anywhere -->
        <section class="QFA pt-4">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="title-block text-center mb-0">
                            <h1>Learn How Queue Works</h1>
                            <p class="text-14">It is best practice to be on time and avoid cancellation whenever you queue virtually.</p>
                            <div class="line-block">
                                <a href="#!">Read More</a>
                                <!-- <span class="bullet"><fa icon="leaf" /></span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-lg-3 col-sm-6 p-3">
                        <div class="box setup-1 mt-lg-5 mt-2 mb-2 mb-lg-5">
                            <!-- <div class="icon">
                                <div class="">
                                    <img src="@/assets/images/icons/icon-4.png" class="img-fluid" alt="" />
                                </div>
                            </div> -->
                            <h5 class="mt-5 mb-3">Getting Started</h5>
                            <p>We recommend users sign up and search for the business or professional of choice.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 p-3">
                        <div class="box setup-2 mt-lg-5 mt-2 mb-2 mb-lg-5">
                            <!-- <div class="icon">
                                <div class="">
                                    <img src="@/assets/images/icons/icon-3.png" class="img-fluid" alt="" />
                                </div>
                            </div> -->
                            <h5 class="mt-5 mb-3">Choose A Business</h5>
                            <p>Click on the business to open the profile, and click on the green button "Live Queue."</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 p-3">
                        <div class="box setup-3 mt-lg-5 mt-2 mb-2 mb-lg-5">
                            <!-- <div class="icon">
                                <div class="">
                                    <img src="@/assets/images/icons/icon-2.png" class="img-fluid" alt="" />
                                </div>
                            </div> -->
                            <h5 class="mt-5 mb-3">Choose A Professional</h5>
                            <p>To queue, click on Join the waitlist and choose a professional available, then select any service listed by the professional.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 p-3">
                        <div class="box setup-4 mt-lg-5 mt-2 mb-2 mb-lg-5">
                            <!-- <div class="icon">
                                <div class="">
                                    <img src="@/assets/images/icons/icon-1.png" class="img-fluid" alt="" />
                                </div>
                            </div> -->
                            <h5 class="mt-5 mb-3">Finish</h5>
                            <p>Congratulations!<br>Add your payment details and Join Queue.</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="row text-center">
                    <div class="col-lg-3 col-sm-6 p-3">
                        <div class="box setup-1">
                            <div class="icon mb-3">
                                <div class="">
                                    <img src="@/assets/images/icons/icon-4.png" class="img-fluid" alt="" />
                                </div>
                            </div>
                            <p class="m-0">We recommend user to sign up and search for the business or professional.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 p-3">
                        <div class="box setup-2">
                            <div class="icon mb-3">
                                <div class="">
                                    <img src="@/assets/images/icons/icon-3.png" class="img-fluid" alt="" />
                                </div>
                            </div>
                            <p class="m-0">Choose the business and open their profile.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 p-3">
                        <div class="box setup-3">
                            <div class="icon mb-3">
                                <div class="">
                                    <img src="@/assets/images/icons/icon-2.png" class="img-fluid" alt="" />
                                </div>
                            </div>
                            <p class="m-0">Choose any professional available and pick the service they are offering.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 p-3">
                        <div class="box setup-4">
                            <div class="icon mb-3">
                                <div class="">
                                    <img src="@/assets/images/icons/icon-1.png" class="img-fluid" alt="" />
                                </div>
                            </div>
                            <p class="m-0">Enter your payment details and join the queue, you’ll not be charged until after the service is completedr.</p>
                        </div>
                    </div>
                </div> -->
            </div>
        </section>
        <!-- End Queue from anywhere -->
    </div>
</template>
<script setup></script>
<style scoped>
    .title-block h1{
        color: #111111;
    }
</style>
