<template>
    <!-- Deals -->
    <section class="deals-section section-padding" v-if="deals.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="title-block text-center">
                        <h1 class="text-start text-md-center">
                            Deals in <fa icon="location-arrow" /> {{storedLoc?.data?.label}}
                            <span class="see-all-button">
                                <router-link :to="'/deals/' + storedLoc?.data?.value">See all <fa icon="long-arrow-alt-right" /></router-link>
                            </span>
                        </h1>
                        <!-- <div class="line-block">
                            <span class="bullet"><fa icon="leaf" /></span>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row flex">
                <div class="col-lg-3 col-md-6 col-10 p-2" v-for="deal in deals" :key="deal.id">
                    <div class="deals-block">
                        <div class="deals-list">
                            <div class="thumb">
                                <router-link :to="'/deal/' + deal.slug"><img v-if="deal.banner" :src="deal.banner" alt="" class="img-fluid" /><img v-else src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/deals/default.jpg" alt="" class="img-fluid" /></router-link>
                                <div class="mi-no" v-if="deal.distance > 0">{{deal.distance}} mi</div>
                                <div class="price-tag"><span class="regular-price strikediagonal">${{deal.total_price}}</span> <span style="font-weight:500">${{deal.discounted_price}}</span></div>
                            </div>
                            <div class="content">
                                <router-link :to="'/deal/' + deal.slug"><h3 class="title">{{deal.title}}</h3></router-link>
                                <p>By {{deal.b_title}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Row -->
        </div>
    </section>
    <!-- End Deals -->
</template>

<script setup>
import { ref, watch } from 'vue'
import { useStorage } from "vue3-storage";
import { useLocationStore } from '@/stores/location'
import axios from 'axios'

const locationStore = useLocationStore()
const storage = useStorage()
const storedLoc = storage.getStorageSync('location')
const deals = ref([])

function getHomePageDeals(currentlocation, location='') {
    axios.get('home_deals',{params: {...currentlocation, location: location}}).then(function (response) {
        if (response.data.status === 'success') {
            deals.value = response.data.data
        }
    });
}

// watch(() => locationStore.currentLocation,(newLocation) => {
//     if(newLocation){
//         getHomePageDeals(newLocation, storedLoc.data?.value)
//     }
// },{ deep: true })

watch([() => locationStore.currentLocation, () => locationStore.location], ([newCurrentLocation, newLocation]) => {
        if(newCurrentLocation && newLocation){
            getHomePageDeals(newCurrentLocation, newLocation?.data?.value)
        }
    },
    { deep: true }
)
</script>

<style scoped>
    /* .deals-section{
        background: white;
    } */
    .deals-list .content {
        padding: 10px 7px;
    }
    .regular-price {
        text-decoration: none;
        margin-right: 3px;
        font-weight: 400;
        font-size: 13px;
    }
    .strikediagonal {
        background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 55%);
    }
    .title-block h1{
        font-weight: bold;
    }
</style>