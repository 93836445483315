<script setup>
import { useStorage } from "vue3-storage";
import { useRouter } from "vue-router";
import { useBusinessTypesStore } from '@/stores/businessTypes'
const router = useRouter()
const store = useBusinessTypesStore()
const storage = useStorage()

const storedLoc = storage.getStorageSync('location')
store.getBusinessTypes('homePage')
const searchIt = async (id='') => {
    const query = {}
    if (id !== '') {
        query.type = id
    }
    router.push({ name:'Listing', params:{location:storedLoc.data.value},query:query})
}
</script>
<template>
    <section class="h-services" v-if="store.hTypes.length > 0">
        <div class="container">
            <div class="row d-none d-md-block">
                <div class="col-xs-12">
                    <div class="title-block text-center">
                        <h1>Explore Service Providers Near You</h1>
                        <!-- <div class="line-block">
                            <span class="bullet"><fa icon="leaf" /></span>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row explore-services">
                <div v-for="type in store.hTypes" :key="type.id" v-on:click.prevent="searchIt(type.id)" class="col-4 col-lg-3 col-md-3 text-center service-box">
                    <img :src="type.icon" :alt="type.title" class="img-fluid" />
                    <h5>
                        <span v-if="type.professional_title">{{ type.professional_title }}</span>
                        <span v-else>{{type.title}}</span>
                    </h5>
                </div>
            </div>
            <div class="row find_more">
                <div class="col-12 text-center mb-0 mt-4 mt-md-5 fs-md-4">
                    <p class="fs-5 cursor_pointer d-inline" v-on:click.prevent="searchIt"><img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/categories/arrow_right.svg">Find more</p>
                </div>
            </div>
        </div>
    </section>
</template>