<template>
    <MainAppContainer>
        <Banner :bannerImage="bannerImage" :bannerTitle="bannerTitle" :bannerSubTitle="bannerSubTitle" />
        <business-types />
        <Deals />
        <!-- Announcement -->
        <section class="announcement-section py-3 py-md-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 text-center">
                        <img src="@/assets/images/img/people.png" alt="Queue" class="img-fluid" />
                    </div>
                    <div class="col-md-7">
                        <div class="content">
                            <!-- <h2>Do you know you can allow clients join a live queue?</h2> -->
                            <h2 class="text-center">Manage last-minute appointments, get unlimited booking, increase clientele.</h2>
                            <!-- <p>OnDaQ’s Queue Management System can be a solution for you.</p> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="businessList-btn text-center text-md-start">
                            <router-link v-if="$storage.getStorageSync('business') && $storage.getStorageSync('business').has_business == 1" :to="{name:'BusinessDashboard'}" class="btn btn-light mb-2"><fa icon="chart-line" /> Manage business</router-link>
                            <router-link v-else :to="{name:'AddBusiness'}" class="btn btn-light mb-2"><fa icon="plus" /> List your business</router-link>
                            <p>
                                No creditcard needed<br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Announcement -->
        <Businesses />
        <BookingAndQueueProcess />
        <DownloadApp />
    </MainAppContainer>
</template>
<script setup>
    import { ref, onMounted } from 'vue'
    import MainAppContainer from '@/components/containers/MainAppContainer.vue'
    import Banner from '@/components/home/Banner'
    import BusinessTypes from '@/components/home/BusinessTypes'
    import Deals from '@/components/home/Deals'
    import Businesses from '@/components/home/Businesses'
    import BookingAndQueueProcess from '@/components/home/BookingAndQueueProcess'
    import DownloadApp from '@/components/common/DownloadApp'
    import axios from 'axios'
    import { useStorage } from 'vue3-storage'
    import { useLocationStore } from '@/stores/location'
    import moment from 'moment'

    const bannerImage = ref('')
    const bannerTitle = ref('')
    const bannerSubTitle = ref('')
    const ip = ref('')
    const storage = useStorage()
    const locationStore = useLocationStore()
    const defaultLocation = ref({data: {"label":"Charlotte, NC","value":"Charlotte-NC"}, date: moment().format('YYYY-MM-DD'), type: 'default'})

    async function getSettings(){
        const resp = await axios.get('settings')
        if (resp.data.status === 'success') {
            if(resp.data.data){
                resp.data.data.map((setting) => {
                    if(setting.key === 'banner_image'){
                        bannerImage.value = setting.value
                    }else if(setting.key === 'banner_title'){
                        bannerTitle.value = setting.value
                    }else if(setting.key === 'banner_sub_title'){
                        bannerSubTitle.value = setting.value
                    }else if(setting.key === 'ip'){
                        ip.value = setting.value
                    }
                })
            }
        }
    }

    onMounted(() => {
        getSettings()

        const storedLoc = storage.getStorageSync('location')
        // Check Current Location permissions
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                // Allowed
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                storage.setStorageSync('currentLocation',{lat: lat, lng: lng, type: 'current'})
                locationStore.currentLocation = {lat: lat, lng: lng, type: 'current'}
                if (storedLoc){
                    locationStore.location = storedLoc
                }else{
                    updateSearchedLocation(lat, lng)
                }
            },function () {
                // Not Allowed
                console.log("Check Location: ","Not Allowed.")
                locationStore.location = storedLoc ? storedLoc : defaultLocation.value
                getLocationFromStorage()
            });
        }else{
            console.log("Check Browser: ","Geolocation is not supported by this browser.")
            locationStore.location = storedLoc ? storedLoc : defaultLocation.value
            getLocationFromStorage()
        }
    })

    function getLocationFromStorage(){
        const currentLocation = storage.getStorageSync('currentLocation')
        if (currentLocation) {
            if (storage.getStorageSync('ip') === ip.value) {
                locationStore.currentLocation = currentLocation
            }else{
                getLocationFromIP()
            }
        }else{
            getLocationFromIP()
        }
    }
    function getLocationFromIP(){
        // Set Default Location
        let locationData = {lat: '35.2027049', lng: '-81.1701694', type: 'default'}
        storage.setStorageSync('currentLocation',locationData)
        
        // Get Location from IP
        axios.get('locIP').then(function (response) {
            if (response.data.status === 'success') {
                if(response.data.data){
                    storage.setStorageSync('currentLocation',{lat: response.data.data?.lat, lng: response.data.data?.lng, type: 'current'})
                    locationStore.currentLocation = {lat: response.data.data?.lat, lng: response.data.data?.lng, type: 'current'}
                    storage.setStorageSync('ip', ip.value)
                }else{
                    locationStore.currentLocation = locationData
                }
            }else{
                locationStore.currentLocation = locationData
            }
        })
        .catch(error => {
            if (error.response) {
                locationStore.currentLocation = locationData
            }
        });    
    }
    function updateSearchedLocation(lat, lng){
        const geocoder = new window.google.maps.Geocoder();
        var latlng = new window.google.maps.LatLng(lat, lng);
        geocoder.geocode({'latLng': latlng}, function(results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                if (results[1]) {
                    //find City, State
                    var loc_obj = {
                        city:'',
                        state:''
                    }
                    results[0].address_components.forEach(element => {
                        if(element.types.indexOf("locality") !== -1){
                            loc_obj.city = element.long_name
                        }else if(element.types.indexOf("administrative_area_level_1") !== -1){
                            loc_obj.state = element.short_name
                        }
                    });
                    locationStore.location = {data: {"label":loc_obj.city+', '+loc_obj.state,"value":loc_obj.city+'-'+loc_obj.state}, date: moment().format('YYYY-MM-DD'), type: 'current-location'}
                } else {
                    locationStore.location = defaultLocation.value
                }
            }else{
                locationStore.location = defaultLocation.value
            }
            storage.setStorageSync('location', locationStore.location)
        });
    }
</script>
<style scoped>
    @media (min-width: 991px){
        .announcement-section h2{
            font-size: 26px;
        }
    }
</style>