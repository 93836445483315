<template>
    <section class="banner" :style="{ 'background-image': 'url('+bannerImage+')' }">
        <div class="px-4 text-center main-wrapper search-text">
            <h1 class="display-5 fw-bold mb-4 d-lg-block d-none"><fa :icon="['fas', 'circle']" style="width:16px" /> {{bannerTitle}} <fa :icon="['fas', 'circle']" class="ms-3" style="width:16px" /> {{bannerSubTitle}}</h1>
            <div class="row d-block d-lg-none">
                <div class="col-12 text-center">
                    <span class="display-5 fw-bold mb-4 text-white  mobile_banner"><fa :icon="['fas', 'circle']" style="width:16px" /> {{bannerTitle}}</span>
                </div>
                <div class="col-12 text-center">
                    <span class="display-5 fw-bold mb-4 text-white  mobile_banner"><fa :icon="['fas', 'circle']" class="ms-3" style="width:16px" /> {{bannerSubTitle}}</span>
                </div>
            </div>
            <div class="col-lg-8 col-md-10 mx-auto onMobile">
                <Search v-if="locationStore.location" />
                <div class="mt-3 d-sm-block"></div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import Search from '@/components/home/Search'
    import { useLocationStore } from '@/stores/location'

    const locationStore = useLocationStore()

    defineProps({ 
        bannerImage: String,
        bannerTitle: String,
        bannerSubTitle: String,
    });
</script>
<style scoped>
    @media screen and (max-width:576px) {
        .svg-inline--fa{
            height: 12px;
            vertical-align: middle;
        }
        .display-5{
            font-size: 25px;
        }
        .onMobile{
            position: relative;
            bottom: -10px;
        }
        .mobile_banner{
            font-family: Helvetica;
        }

    }
    @media screen and (max-width:390px) {
        .display-5{
            font-size: 20px;
        }
        .svg-inline--fa{
            height: 9px;
        }
    }
</style>